var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-text',{staticClass:"pa-0",style:(`height: ${_vm.height}px; overflow: auto`)},[_c('a-table-f-api',{ref:"table",attrs:{"api":_vm.m.api,"model":_vm.model,"searchable":false,"defaults":{
        filters: { lead_id: _vm.id, user_id: _vm.getAccess(`leads.viewAll`) || _vm.id ? undefined : _vm.$root.profile.id },
        sort: [
          { key: 'status', order: 'ASC' },
          { key: 'id', order: 'DESC' },
        ],
        paramName: 'leadActions',
      }},on:{"click":function($event){return _vm.onClickRow($event)}},scopedSlots:_vm._u([{key:"item.actions",fn:function({ row }){return [_c('div',{staticClass:"d-flex"},[(row.status == 1 && _vm.getAccess('leads.actions.create'))?_c('v-btn',{staticClass:"ma-0 ml-0",attrs:{"x-small":"","fab":"","color":"green","title":"Выполнить"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('action', { task: row, type: 'done' })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")])],1):_vm._e(),(row.status == 1 && false)?_c('v-btn',{staticClass:"ma-0 ml-1",attrs:{"x-small":"","fab":"","color":"red","title":"Отменить"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('action', { task: row, type: 'cancel' })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e()],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }